import '@babel/polyfill';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import {
	ProclaimDigitalSignage,
	DigitalSignageClient,
	OnAirHubClient,
	OpenWeatherMapClient,
} from '@faithlife/proclaim-digital-signage';

import '@faithlife/proclaim-digital-signage/dist/proclaim-digital-signage.css';

const onAirHubClient = new OnAirHubClient();
const digitalSignageClient = new DigitalSignageClient({
	serviceWorkerPath: '/dist/signage-service-worker.js',
});
const weatherClient = new OpenWeatherMapClient();

ReactDOM.render(
	<ProclaimDigitalSignage
		{...window.initialProps}
		digitalSignageClient={digitalSignageClient}
		onAirHubClient={onAirHubClient}
		weatherClient={weatherClient}
	/>,
	document.getElementById('app')
);

